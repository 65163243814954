;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"94ff6b992c68c52478943709ddb4dc3108136116"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import { browserVersionSupported } from './src/utils/minimumRequirements'

Sentry.init({
  beforeSend: (event) => {
    if (!browserVersionSupported) {
      return null
    }

    return event
  },

  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,

  allowUrls: ['app.development.serenis.it', 'app.serenis.it'],
})
